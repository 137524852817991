import { Bifrost } from '@intility/bifrost-react';
import nbNO from '@intility/bifrost-react/locales/nb-no';
import { Suspense } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import { LandingNavbar } from '@/features/landing/components';

export const PublicApp = () => {
  return (
    <Suspense fallback={<></>}>
      <Bifrost locale={nbNO}>
        <div className='bf-darkmode no-common-ligatures ss-02 ss-03 ss-04 relative flex flex-col bg-bfc-base font-display text-mist-grey'>
          <div className={'sticky top-0 z-50'}>
            <LandingNavbar />
          </div>

          <div className='relative -mt-navTopHeight flex w-full flex-col'>
            <ScrollRestoration />
            <Outlet />
          </div>
        </div>
      </Bifrost>
    </Suspense>
  );
};
