import type { EventMessage } from '@azure/msal-browser';
import { EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { captureException } from '@sentry/browser';
import type { DefaultOptions } from '@tanstack/react-query';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { instance } from '@/auth';
import { AuthInteractionModal } from '@/components/Elements/Modal';

const queryConfig: DefaultOptions = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3, // 3 minutes
  },
};

export const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const [loginRequired, setLoginRequired] = useState(false);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: EventMessage) => {
      const eventType = message.eventType;
      const interactionType = message.interactionType;

      const acquireTokenRedirectSuccess =
        eventType === EventType.ACQUIRE_TOKEN_SUCCESS && interactionType === 'redirect';

      if (acquireTokenRedirectSuccess || eventType === EventType.LOGIN_SUCCESS) {
        if (loginRequired) {
          setLoginRequired(false);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: queryConfig,
      queryCache: new QueryCache({
        onError: error => {
          captureException(error);

          if (error instanceof InteractionRequiredAuthError) {
            setLoginRequired(true);
          }
        },
      }),
    });
  });

  return (
    <QueryClientProvider client={queryClient}>
      <>
        {loginRequired ? (
          <AuthInteractionModal
            isOpen={loginRequired}
            onRequestClose={() => setLoginRequired(false)}
          />
        ) : (
          children
        )}
      </>
    </QueryClientProvider>
  );
};
