import { API } from '@/auth/config';
import { api } from '@/auth/fetch';
import { usersDetailSchema } from './getUsersDetail.schema';

export const getUsersDetail = async (name: string | undefined) => {
  if (!name) return { items: [] };

  const endpoint = `${API.Sim.url}/v1/users`;
  const data = await api
    .get(endpoint, { searchParams: { displayName: name, skip: 0, limit: 9999 } })
    .json();

  return usersDetailSchema.parse(data);
};
