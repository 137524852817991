import { z } from 'zod';

const envSchema = z.object({
  environment: z.union([z.literal('development'), z.literal('production')]),
  version: z.string().optional(),
  portalAppId: z.string(),
  sentryDsn: z.string(),
  sentryEnvironment: z.string(),
  sentryRelease: z.string().optional(),
  coreApiUrl: z.string(),
  coreApiAppId: z.string(),
  simApiUrl: z.string(),
  simApiAppId: z.string(),
  consumptionApiUrl: z.string(),
  consumptionApiAppId: z.string(),
  usersApiUrl: z.string(),
  usersApiAppId: z.string(),
  notifyApiUrl: z.string(),
  notifyApiAppId: z.string(),
  publishApiUrl: z.string(),
});

export type Env = z.infer<typeof envSchema>;

export const env: Env = {
  environment: import.meta.env.VITE_ENVIRONMENT,
  version: import.meta.env.VITE_VERSION,
  portalAppId: import.meta.env.VITE_PORTAL_APP_ID,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  sentryEnvironment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  sentryRelease: import.meta.env.VITE_SENTRY_RELEASE,
  coreApiUrl: import.meta.env.VITE_CORE_API_URL,
  coreApiAppId: import.meta.env.VITE_CORE_API_APP_ID,
  simApiUrl: import.meta.env.VITE_SIM_API_URL,
  simApiAppId: import.meta.env.VITE_SIM_API_APP_ID,
  consumptionApiUrl: import.meta.env.VITE_CONSUMPTION_API_URL,
  consumptionApiAppId: import.meta.env.VITE_CONSUMPTION_API_APP_ID,
  usersApiUrl: import.meta.env.VITE_USERS_API_URL,
  usersApiAppId: import.meta.env.VITE_USERS_API_APP_ID,
  notifyApiUrl: import.meta.env.VITE_NOTIFY_API_URL,
  notifyApiAppId: import.meta.env.VITE_NOTIFY_API_APP_ID,
  publishApiUrl: import.meta.env.VITE_PUBLISH_API_URL,
};

envSchema.parse(env);
