export enum CustomerApiRole {
  Admin = 'Api.Customer.Admin',
  Approver = 'Api.Customer.Approver',
  User = 'Api.Customer.User',
}

export enum CustomerRole {
  Admin = 'Admin',
  Approver = 'Approver',
  User = 'User',
}
