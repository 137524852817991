import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Grid, Icon } from '@intility/bifrost-react';

import type { CategoryMetadata } from '../types/products';

interface ProductCategorySellingPointsProps {
  categoryMetadata: CategoryMetadata | undefined;
}

export const ProductCategorySellingPoints = ({
  categoryMetadata,
}: ProductCategorySellingPointsProps) => {
  return (
    <Grid
      medium={4}
      small={2}
      className='gap-bfs-24 rounded-xl border border-bfc-base-c-dimmed bg-bfc-base-3 px-bfs-12 py-bfs-16'
    >
      {categoryMetadata?.sellingPoints.map((sellingPoint, index) => (
        <span className='flex gap-bfs-8' key={index}>
          <Icon className='mt-[2px]' icon={faCheck} color='var(--bfc-theme)' />
          <p className='bf-p !m-0'>{sellingPoint}</p>
        </span>
      ))}
    </Grid>
  );
};
