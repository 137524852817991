import { useQuery } from '@tanstack/react-query';

import { API } from '@/auth/config';
import { api } from '@/auth/fetch';
import { QueryKey } from '@/types/react-query';
import { myUserSchema } from './getMyUser.schema';

const getMyUser = async () => {
  const endpoint = `${API.Sim.url}/v1/user-access-management/me`;
  const data = await api.get(endpoint).json();

  return myUserSchema.parse(data);
};

export const useMyUser = () => {
  return useQuery({
    queryKey: [QueryKey.MyUser],
    queryFn: getMyUser,
    staleTime: 1000 * 60 * 60,
  });
};
