import { Navigate, Route, Routes } from 'react-router-dom';

import { SubscriptionCategoryType } from '@/features/subscriptions/api';
import { ProductCategories } from './ProductCategories';
import { ProductCategoriesOverview } from './ProductCategoriesOverview';
import { ProductCategory } from './ProductCategory';

export const ProductsRoutes = () => {
  return (
    <Routes>
      <Route path='' element={<ProductCategoriesOverview />} />
      <Route path='all' element={<ProductCategories />} />
      <Route path='voice' element={<ProductCategory category={SubscriptionCategoryType.VOICE} />} />
      <Route path='data' element={<ProductCategory category={SubscriptionCategoryType.DATA} />} />
      <Route path='iot' element={<ProductCategory category={SubscriptionCategoryType.IOT} />} />
      <Route path='*' element={<Navigate to='.' />} />
    </Routes>
  );
};
