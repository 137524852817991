import { z } from 'zod';

const userDetailSchema = z.object({
  billingCompanyId: z.number(),
  billingCompanyName: z.string(),
  companyCode: z.string(),
  companyId: z.string(),
  companyName: z.string(),
  department: z.string().nullable(),
  displayName: z.string(),
  firstName: z.string(),
  language: z.string(),
  lastName: z.string(),
  locationCode: z.string(),
  locationId: z.string(),
  locationName: z.string(),
  phoneNumber: z.string(),
  status: z.string(),
  tenantId: z.string(),
  upn: z.string(),
  userId: z.string(),
  userName: z.string(),
});

export const usersDetailSchema = z.object({
  items: z.array(userDetailSchema),
  totalItems: z.number(),
});

export type UserDetailDto = z.infer<typeof userDetailSchema>;
export type UsersDetailDto = z.infer<typeof usersDetailSchema>;
