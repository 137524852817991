import delve from 'dlv';
import { dset } from 'dset';
import { create } from 'zustand';

import { type User } from '@/components/Elements/Select/UserSelect';
import { type Order, type OrderUpdate } from '@/features/order/types';
import { type DataPlanDto } from '@/features/products/api';
import { SubscriptionCategoryType } from '@/features/subscriptions/api';

interface State {
  orders: Order[];
  actions: {
    addOrder: (orderId: string, product: DataPlanDto, user?: User) => void;
    updateOrder: (id: string, keysAndValues: OrderUpdate[]) => void;
    updateAllOrders: (keysAndValues: OrderUpdate[]) => void;
    removeOrder: (orderId: string) => void;
    removeOrders: (productId: string) => void;
    clearOrders: () => void;
    getOrderById: (id: string) => Order | undefined;
  };
}

const useOrderStore = create<State>()((set, get) => ({
  orders: [],
  actions: {
    addOrder: (orderId, product, user) => {
      let userForm: Order['forms']['user'] = { isValid: false };

      if (user) {
        userForm = {
          isValid: true,
          data: {
            user: user,
          },
        };
      }

      return set(state => ({
        orders: [
          ...state.orders,
          {
            id: orderId,
            dataPlan: product,
            subscriptionOwnerOid: null,
            billingCompany: null,
            description: null,
            sim: null,
            porting: null,
            forms: {
              user: userForm,
              billing: { isValid: true },
              sim: { isValid: false },
              porting: {
                isValid: product.subscriptionCategoryType !== SubscriptionCategoryType.VOICE,
              },
            },
          },
        ],
      }));
    },
    removeOrder: orderId =>
      set(state => ({
        orders: state.orders.filter(order => order.id !== orderId),
      })),
    removeOrders: productId =>
      set(state => ({
        orders: state.orders.filter(order => order.dataPlan.dataPlanId !== productId),
      })),
    updateOrder: (id, keysAndValues) => {
      return set(state => ({
        orders: state.orders.map(order => {
          if (order.id === id) {
            keysAndValues.forEach(([key, value]) => {
              dset(order, key, value);
            });

            return order;
          } else {
            return order;
          }
        }),
      }));
    },
    updateAllOrders: keysAndValues =>
      set(state => ({
        orders: state.orders.map(order => {
          keysAndValues.forEach(([key, value, conditions = []]) => {
            let shouldUpdate = true;

            for (const condition of conditions) {
              const path = condition.path;
              const expectedValue = condition.value;

              if (delve(order, path) !== expectedValue) {
                shouldUpdate = false;
                break;
              }
            }

            if (shouldUpdate) {
              dset(order, key, value);
            }
          });

          return order;
        }),
      })),
    getOrderById: id => get().orders.find(order => order.id === id),
    clearOrders: () => set(() => ({ orders: [] })),
  },
}));

export const useOrders = () => useOrderStore(state => state.orders);

export const useOrderActions = () => useOrderStore(state => state.actions);
