import { Button } from '@intility/bifrost-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from '@/routes/config';

interface ErrorPageProps {
  resetError?: () => void;
  handleMutationReset?: () => void;
  correlationId?: string;
}

export const ErrorPage = ({ correlationId, resetError, handleMutationReset }: ErrorPageProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isOnOverviewPage = routes.overview.path.includes(location.pathname);

  return (
    <div
      className='m-auto flex max-w-xl flex-col items-center justify-center px-bfs-24'
      style={{ height: 'calc(100vh - var(--bf-nav-top-height) - var(--bfs48))' }}
    >
      <div className='bf-content'>
        <h1>Noe har gått galt</h1>

        <p className='!mb-bfs-24 !mt-0 !text-base text-bfc-base-c-2'>
          {correlationId ? (
            <>
              Noe har gått galt. Vennligst prøv igjen senere eller kontakt{' '}
              <a
                href={`https://chat.intility.no`}
                target='_blank'
                className='bf-link'
                rel='noreferrer'
              >
                Intility Support
              </a>{' '}
              og oppgi denne koden: <b className='text-bfc-base-c'>{correlationId}</b>
            </>
          ) : (
            <>
              Våre utviklere har blitt informert.{' '}
              <a href='/' className='bf-link' rel='noreferrer'>
                Last inn siden på nytt
              </a>{' '}
              eller trykk på knappen nedenfor.
            </>
          )}
        </p>

        <Button
          variant='filled'
          onClick={() => {
            if (resetError) {
              navigate(isOnOverviewPage ? routes.subscriptions.user.path : routes.overview.path);
              resetError();
            } else if (handleMutationReset) {
              handleMutationReset();
            }
          }}
        >
          {isOnOverviewPage ? 'Til abonnementer' : handleMutationReset ? 'Tilbake' : 'Til forsiden'}
        </Button>
      </div>
    </div>
  );
};
