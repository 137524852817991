import { Spinner } from '@intility/bifrost-react';

import { Errorbox } from '@/components/Errors';
import { useMyUser } from '@/features/user-management/api';
import { CreateUser } from './CreateUser';

export const UserRoleValidator = ({ children }: { children: React.ReactNode }) => {
  const myUser = useMyUser();

  if (myUser.isError) {
    return (
      <div className='m-auto max-w-screen-md p-bfs-40'>
        <Errorbox queries={[myUser]} />
      </div>
    );
  }

  if (myUser.isPending) {
    return <Spinner overlay label='Laster...' />;
  }

  if (myUser.data.role === null) {
    return <CreateUser />;
  }

  // User has a valid role
  return <>{children}</>;
};
