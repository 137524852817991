import { z } from 'zod';

const EsimStatus = {
  Installed: 'INSTALLED',
  Released: 'RELEASED',
  Disabled: 'DISABLED',
  Deleted: 'DELETED',
  Enabled: 'ENABLED',
  Unknown: 'UNKNOWN',
} as const;

export type EsimStatus = (typeof EsimStatus)[keyof typeof EsimStatus];

export const esimStatusSchema = z.object({
  simId: z.string(),
  esimStatus: z.object({
    convertedStatus: z.nativeEnum(EsimStatus),
    carrierStatus: z.string(),
  }),
});

export type EsimStatusDto = z.infer<typeof esimStatusSchema>;
