import { z } from 'zod';

export const userRoles = ['Admin', 'Approver', 'User'] as const;

const userRoleSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  userPrincipalName: z.string(),
  role: z.enum(userRoles),
});

export const usersRoleSchema = z.object({
  items: z.array(userRoleSchema),
});

export type UserRoleDto = z.infer<typeof userRoleSchema>;
export type UsersRoleDto = z.infer<typeof usersRoleSchema>;
export type UserRole = (typeof userRoles)[number];
