import { z } from 'zod';

export const subscriptionsDistributionSchema = z.object({
  items: z.array(
    z.object({
      subscriptionCategoryType: z.enum(['VOICE', 'DATA', 'IOT', 'COMLINE']), // replace with nativeEnum when shared-utils is published to artifactory
      count: z.number(),
      totalMonthlyPrice: z.number(),
    }),
  ),
});

export type SubscriptionsDistributionDto = z.infer<typeof subscriptionsDistributionSchema>;
export type SubscriptionDistributionDto = SubscriptionsDistributionDto['items'][number];
