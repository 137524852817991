import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Modal } from '@intility/bifrost-react';
import type { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import { createContext, useState } from 'react';

interface Props {
  children: ReactNode;
}

type HandleModalType = (content?: ReactElement, header?: string, icon?: IconDefinition) => void;

interface ModalContextProps {
  modal?: boolean;
  handleModal: HandleModalType;
  modalContent?: ReactElement;
  modalHeader?: string;
  setModal?: Dispatch<SetStateAction<boolean>>;
  modalHeaderIcon?: IconDefinition;
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

const ModalProvider = ({ children }: Props) => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState<ReactElement>(<></>);
  const [modalHeader, setModalHeader] = useState('Default header');
  const [modalHeaderIcon, setModalHeaderIcon] = useState<IconDefinition>();

  const handleModal = (content = <></>, header = '', icon: IconDefinition | undefined) => {
    setModal(old => !old);
    setModalContent(content);
    setModalHeader(header);
    setModalHeaderIcon(icon);
  };

  return (
    <ModalContext.Provider
      value={{ modal, modalContent, handleModal, modalHeader, modalHeaderIcon }}
    >
      <Modal
        onRequestClose={() => setModal(false)}
        isOpen={modal}
        header={modalHeader}
        className='overflow-visible'
      >
        {modal && modalContent}
      </Modal>

      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
