import type { ReactNode } from 'react';

import { cn } from '@/utils/clsx';
import { Head } from '../Head';

type ContentLayoutProps = {
  children: ReactNode;
  title?: string;
  description?: string;
  noPadding?: boolean;
  maxWidth?: string;
  className?: string;
};

export const ContentLayout = ({
  children,
  title,
  description,
  noPadding = false,
  className,
}: ContentLayoutProps) => {
  return (
    <>
      {title && <Head title={title} description={description} />}

      <div
        className={cn(
          'm-auto flex max-w-[1920px] flex-col justify-center',
          { 'bfl-page-padding': !noPadding },
          className,
        )}
      >
        {children}
      </div>
    </>
  );
};
