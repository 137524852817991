import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Spinner } from '@intility/bifrost-react';
import './fullScreenLoader.scss';
import type { ReactElement } from 'react';

/**
 * Author: aa589
 * Loading (c) 2021
 * Desc: Shared Loading component that displays a loading card center in the page
 * Created: 15.12.2021
 */

interface LoadingProps {
  text?: string;
  errorMessage?: string;
}

export const FullScreenLoader = ({ text, errorMessage }: LoadingProps): ReactElement => {
  return errorMessage ? (
    <div className='bf-overlay' role='dialog'>
      <div className='bf-spinner-container items-center'>
        <Icon icon={faExclamationTriangle} className='h-20 w-20' color='var(--bfc-alert)' />
        <p className='bf-spinner-label'>{errorMessage}</p>
      </div>
    </div>
  ) : (
    <Spinner overlay size={80} label={text ?? 'Laster...'} />
  );
};
