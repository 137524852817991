import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Drawer, Icon } from '@intility/bifrost-react';
import type { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import { createContext, useState } from 'react';

interface Props {
  children: ReactNode;
}

type HandleDrawerType = (content?: ReactElement, header?: string, icon?: IconDefinition) => void;

interface DrawerContextProps {
  drawer?: boolean;
  handleDrawer: HandleDrawerType;
  drawerContent?: ReactElement;
  drawerHeader?: string;
  drawerHeaderIcon?: IconDefinition;
  setDrawer?: Dispatch<SetStateAction<boolean>>;
}

const DrawerContext = createContext<DrawerContextProps>({} as DrawerContextProps);

const DrawerProvider = ({ children }: Props) => {
  const [drawer, setDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState<ReactElement>(<></>);
  const [drawerHeader, setDrawerHeader] = useState('Default header');
  const [drawerHeaderIcon, setDrawerHeaderIcon] = useState<IconDefinition>();
  const handleDrawer = (
    content = <></>,
    header = '',
    icon: IconDefinition | undefined = undefined,
  ) => {
    setDrawer(!drawer);
    setDrawerContent(content);
    setDrawerHeader(header);
    setDrawerHeaderIcon(icon);
  };

  return (
    <DrawerContext.Provider
      value={{ drawer, drawerContent, handleDrawer, drawerHeader, setDrawer, drawerHeaderIcon }}
    >
      <Drawer
        onRequestClose={() => handleDrawer()}
        isOpen={drawer}
        header={
          <>
            {drawerHeaderIcon && <Icon className={'mr-4'} icon={drawerHeaderIcon} />}
            {drawerHeader}
          </>
        }
        overlay
      >
        {drawer && drawerContent}
      </Drawer>
      {children}
    </DrawerContext.Provider>
  );
};

DrawerContext.displayName = 'DrawerContext';

export { DrawerContext, DrawerProvider };
