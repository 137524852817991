import type { ReactElement } from 'react';
import { createContext, useEffect, useState } from 'react';

import useLocalStorage from '@/hooks/useLocalStorage';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

interface PwaContextProps {
  pwaInstallPrompt: () => void;
  showPwaInstall: boolean;
}

interface Props {
  children: ReactElement;
}

const A2HSContext = createContext<PwaContextProps>({} as PwaContextProps);

const A2HSProvider = ({ children }: Props) => {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent>(
    {} as BeforeInstallPromptEvent,
  );
  const [showPwaInstall, setShowPwaInstall] = useState(false);
  const [promptStatus, setPromptStatus] = useLocalStorage('PwaPrompt', {});

  window.addEventListener('beforeinstallprompt', e => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later
    setShowPwaInstall(true);
    setDeferredPrompt(e as BeforeInstallPromptEvent);
  });

  useEffect(() => {
    if (
      ('standalone' in window.navigator && window.navigator['standalone']) ||
      promptStatus?.hasAccepted
    ) {
      setShowPwaInstall(false);
    }
  }, [promptStatus]);

  const pwaInstallPrompt = () => {
    deferredPrompt.prompt().then(() =>
      deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          setPromptStatus({ hasAccepted: true, atDate: new Date() });
        } else {
          window.localStorage.setItem(
            'PwaPrompt',
            JSON.stringify({ hasAccepted: false, atDate: new Date() }),
          );
        }
      }),
    );
  };

  return (
    <A2HSContext.Provider value={{ pwaInstallPrompt, showPwaInstall }}>
      {children}
    </A2HSContext.Provider>
  );
};

export { A2HSContext, A2HSProvider };
