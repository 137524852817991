import { jwtDecode } from 'jwt-decode';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import { API } from '@/auth/config';
import { useToken } from '@/lib/auth';
import { type AzureToken } from '@/types/azure';
import { CustomerApiRole } from '@/types/intility-sim';

interface AuthorizationContextProps {
  isCustomerAdmin: boolean;
}

const AuthorizationContext = createContext<AuthorizationContextProps>(
  {} as AuthorizationContextProps,
);

interface AuthorizationProviderProps {
  children: ReactNode;
}

const AuthorizationProvider = ({ children }: AuthorizationProviderProps) => {
  const token = useToken([API.Sim.scope]);
  const [isCustomerAdmin, setIsCustomerAdmin] = useState(false);

  const handleSetCustomerAdmin = (isAdmin: boolean) => {
    setIsCustomerAdmin(isAdmin);
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode<AzureToken>(token.accessToken);

      if (decodedToken.roles) {
        return handleSetCustomerAdmin(decodedToken.roles.includes(CustomerApiRole.Admin));
      }
    }
  }, [token]);

  return (
    <AuthorizationContext.Provider value={{ isCustomerAdmin }}>
      {children}
    </AuthorizationContext.Provider>
  );
};

const useIsAdmin = () => {
  const { isCustomerAdmin } = useContext(AuthorizationContext);

  return isCustomerAdmin;
};

export { AuthorizationProvider, useIsAdmin };
