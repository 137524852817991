// https://gitlab.intility.com/DeveloperServices/portal2/portal-frontend/-/blob/master/src/auth/ConsentModal.tsx

import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { Button, Inline, Modal } from '@intility/bifrost-react';

import { loginRequest } from '@/lib/auth';

export const AuthInteractionModal = ({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose?: () => void;
}) => {
  const { login } = useMsalAuthentication(InteractionType.None, loginRequest);
  const { inProgress } = useMsal();

  return (
    <Modal
      header={'Ny innlogging kreves'}
      noCloseOnEsc
      noCloseButton
      noCloseOnOverlayClick
      isOpen={isOpen}
    >
      <div className='max-w-[650px]'>
        Sesjonen din er utløpt og du må logge inn på nytt for at Intility SIM skal fungere.
        Vennligst trykk på knappen nedenfor.
      </div>

      <Inline className='mt-bfs-24'>
        <Inline.Separator />

        <Button
          onClick={() => {
            // clear authentication cache
            sessionStorage.clear();

            // reload page
            window.location.reload();
          }}
        >
          Last inn siden på nytt
        </Button>
        <Button
          onClick={async () => {
            const result = await login(InteractionType.Popup, {
              ...loginRequest,
              redirectUri: window.location.origin,
            });

            if (result) {
              onRequestClose?.();
            }
          }}
          variant='filled'
          disabled={inProgress !== 'none'}
        >
          Logg inn
        </Button>
      </Inline>
    </Modal>
  );
};
