import { useQuery } from '@tanstack/react-query';

import { API } from '@/auth/config';
import { api } from '@/auth/fetch';
import { type Carrier, type SubscriptionCategoryType } from '@/features/subscriptions/api';
import { QueryKey } from '@/types/react-query';
import { dataPlansSchema } from './getDataPlans.schema';

type QueryParameters = {
  carrier?: Carrier;
  subscriptionCategoryType?: SubscriptionCategoryType;
};

const getDataPlans = async (queryParameters?: QueryParameters) => {
  const endpoint = `${API.Sim.url}/v1/data-plans`;
  const data = await api
    .get(endpoint, {
      searchParams: queryParameters,
    })
    .json();

  return dataPlansSchema.parse(data);
};

export const useDataPlans = (queryParameters?: QueryParameters) => {
  return useQuery({
    queryKey: [QueryKey.DataPlans, queryParameters],
    queryFn: () => getDataPlans(queryParameters),
  });
};
