import { type SubscriptionCategoryType } from '@/features/subscriptions/api';

export const CategoryFriendlyName: Record<SubscriptionCategoryType, string> = {
  DATA: 'Intility Mobilt Bredbånd',
  VOICE: 'Intility Mobilabonnement',
  IOT: 'Intility IoT-abonnement',
  COMLINE: 'Intility Router',
};

export const HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY = 'hasTriedInteractive';
