import { useQuery } from '@tanstack/react-query';

import { API } from '@/auth/config';
import { api } from '@/auth/fetch';
import { QueryKey } from '@/types/react-query';
import { termsAgreementSchema } from './getTermsAgreement.schema';

const getTermsAgreement = async () => {
  const endpoint = `${API.Sim.url}/v1/company/terms-of-services`;
  const data = await api.get(endpoint).json();

  return termsAgreementSchema.parse(data);
};

export const useTermsAgreement = () => {
  return useQuery({
    queryKey: [QueryKey.TermsAgreementStatus],
    queryFn: getTermsAgreement,
    staleTime: 1000 * 60 * 60,
  });
};
