import { Client } from '@microsoft/microsoft-graph-client';
import type { User } from '@microsoft/microsoft-graph-types';
import { useQuery } from '@tanstack/react-query';

import { API } from '@/auth/config';
import { useToken } from '@/lib/auth';
import { QueryKey } from '@/types/react-query';

export const useGraph = () => {
  const token = useToken([API.Graph.scope]);

  const initGraphClient = (accessToken: string) => {
    return Client.init({
      authProvider: (done: (x: null, token: string) => void) => done(null, accessToken),
    });
  };

  const client = token && initGraphClient(token.accessToken);

  const fetchGraphUser = async () => {
    if (client) {
      const userImg: Blob = await client.api('/me/photos/240x240/$value').get();
      const userInfo: User = await client
        .api('me/')
        .query('$select=givenName,surname,companyName,userPrincipalName,mobilePhone')
        .get();

      const userImgUrl = window.URL.createObjectURL(userImg);

      return { userImgUrl, userInfo };
    } else {
      return { userImgUrl: '', userInfo: {} as User };
    }
  };

  return useQuery({
    queryKey: [QueryKey.MSGraph],
    queryFn: fetchGraphUser,
    staleTime: Infinity,
    enabled: !!token,
    retry: false,
  });
};
