import { faArrowRightLong, faBars, faX } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon } from '@intility/bifrost-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import IntilityLogoDark from '@/assets/IntilityLogoZodiacBlue.svg';
import IntilityLogo from '@/assets/intilityLogoWhite.svg';
import { routes } from '@/routes/config';
import { cn } from '@/utils/clsx';
import { useScrollPosition } from '../hooks/useScrollPosition';

type NavLink = {
  label: string;
  href: string;
};

const navLinks: NavLink[] = [
  {
    label: 'Mobilabonnement',
    href: '/mobilabonnement',
  },
  {
    label: 'Mobilt bredbånd',
    href: '/mobiltbredband',
  },
  {
    label: 'IoT',
    href: '/iot',
  },
];

export const LandingNavbar = () => {
  const { scrollPosition } = useScrollPosition();
  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false);

  const hasScrolled = scrollPosition > 0;
  const isPageScrolledAndMenuNotOpen = hasScrolled && !hamburgerMenuIsOpen;

  return (
    <nav
      className={cn(
        'grow px-bfs-24 py-bfs-16 font-display duration-300 ease-in-out sm:px-bfs-40 md:px-bfs-80',
        {
          'bg-mist-grey': isPageScrolledAndMenuNotOpen,
          'bg-transparent': !isPageScrolledAndMenuNotOpen,
          'bg-zodiac-blue': hamburgerMenuIsOpen,
        },
      )}
    >
      <div className={'m-auto flex max-w-landing-content items-center justify-between'}>
        <Link to={routes.landing.base.path}>
          <img
            src={isPageScrolledAndMenuNotOpen ? IntilityLogoDark : IntilityLogo}
            className='h-7'
            alt='Intility'
          />
        </Link>

        <div className='flex items-center justify-center gap-[20px]'>
          <div
            className={cn({
              'text-zodiac-blue': isPageScrolledAndMenuNotOpen,
              'text-mist-grey': !isPageScrolledAndMenuNotOpen,
            })}
          >
            <button
              onClick={() => setHamburgerMenuIsOpen(!hamburgerMenuIsOpen)}
              data-collapse-toggle='navbar-default'
              type='button'
              className={'to-medium inline-flex items-center rounded-md p-2'}
              aria-controls='navbar-default'
              aria-expanded='false'
            >
              <span className='sr-only'>Open main menu</span>
              <Icon className='h-5 w-5' icon={hamburgerMenuIsOpen ? faX : faBars} />
            </button>

            <div className={'from-medium flex items-center gap-bfs-16'}>
              {navLinks.map(link => (
                <Link
                  key={link.label}
                  to={link.href}
                  className='group text-base font-semibold leading-6 tracking-normal'
                >
                  {link.label}
                  <span
                    className={cn(
                      'block h-[2px] max-w-0 transition-all duration-300 group-hover:max-w-full',
                      {
                        'bg-zodiac-blue': hasScrolled,
                        'bg-sea-green': !hasScrolled,
                      },
                    )}
                  />
                </Link>
              ))}
            </div>
          </div>

          <div className='flex items-center gap-bfs-16'>
            <Link to={routes.products.overview.path} className='from-small'>
              <Button
                pill
                variant='flat'
                className={cn(
                  'rounded-[30px] border py-bfs-8 pl-bfs-12 font-semibold transition duration-200',
                  {
                    'border-zodiac-blue text-zodiac-blue hover:bg-zodiac-blue hover:text-mist-grey':
                      isPageScrolledAndMenuNotOpen,
                    'border-mist-grey bg-transparent text-mist-grey hover:border-zodiac-blue hover:bg-mist-grey hover:text-zodiac-blue':
                      !isPageScrolledAndMenuNotOpen,
                  },
                )}
              >
                Bestill
                <Icon icon={faArrowRightLong} className='from-small ml-bfs-4' />
              </Button>
            </Link>

            <Link to={routes.subscriptions.user.path}>
              <Button
                pill
                variant='flat'
                className={cn(
                  'rounded-[30px] border !border-sea-green py-bfs-8 pl-bfs-12 font-semibold transition duration-200',
                  {
                    'bg-sea-green text-zodiac-blue hover:bg-transparent hover:text-sea-green':
                      !isPageScrolledAndMenuNotOpen,
                    '!border-zodiac-blue bg-zodiac-blue text-mist-grey hover:bg-transparent hover:text-zodiac-blue':
                      isPageScrolledAndMenuNotOpen,
                    '!border-sea-green': hamburgerMenuIsOpen,
                  },
                )}
              >
                SIM Portal
                <Icon icon={faArrowRightLong} className='from-small ml-bfs-4' />
              </Button>
            </Link>
          </div>
        </div>

        {hamburgerMenuIsOpen && (
          <div
            className={
              'to-medium absolute left-0 top-bfs-56 mt-bfs-4 h-screen w-full animate-[slideDownFadeIn_0.1s_ease-in-out] bg-zodiac-blue'
            }
          >
            {navLinks.map(link => (
              <Link
                onClick={() => setHamburgerMenuIsOpen(false)}
                key={link.label}
                to={link.href}
                className='block px-bfs-24 py-bfs-16 text-right text-lg font-semibold leading-6 tracking-normal text-white tablet:text-center'
              >
                {link.label}
              </Link>
            ))}

            <Link
              to={routes.products.overview.path}
              className='to-small block px-bfs-24 py-bfs-16 text-right text-lg font-semibold leading-6 tracking-normal text-white tablet:text-center'
            >
              Bestill
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};
