import { Breadcrumbs, Grid } from '@intility/bifrost-react';
import { Link } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { routes } from '@/routes/config';
import { categoryMetadata } from '../assets/metadata';
import { OtherProductsButtons } from '../components/OtherProductsButtons';
import { ProductCategorySellingPoints } from '../components/ProductCategorySellingPoints';
import { ProductsDisplay } from '../components/ProductsDisplay';
import type { SubscriptionProductType } from '../types/products';

interface ProductCategoryProps {
  category: SubscriptionProductType;
}

export const ProductCategory = ({ category }: ProductCategoryProps) => {
  const categoryInfo = categoryMetadata[category];

  return (
    <ContentLayout title='Velg abonnement' className='max-w-6xl'>
      <Breadcrumbs>
        <Breadcrumbs.Item>
          <Link to={routes.products.overview.path}>Kategorier</Link>
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>{categoryInfo.categoryHeader}</Breadcrumbs.Item>
      </Breadcrumbs>

      <h1 className='bf-h1 mt-0'>{categoryInfo.categoryHeader}</h1>
      <p className='my-bfs-16 text-sm'>{categoryInfo.categoryIngress}</p>

      <Grid className='gap-bfs-24'>
        <ProductCategorySellingPoints categoryMetadata={categoryInfo} />
        <ProductsDisplay category={category} />

        <OtherProductsButtons category={category} />
      </Grid>
    </ContentLayout>
  );
};
