import { useMutation } from '@tanstack/react-query';

import { API } from '@/auth/config';
import { api } from '@/auth/fetch';

const createMyUser = async () => {
  const endpoint = `${API.Sim.url}/v1/user-access-management/me`;

  await api.post(endpoint).json();
};

export const useCreateMyUser = () => {
  return useMutation({
    mutationFn: () => createMyUser(),
  });
};
