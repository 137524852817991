import { z } from 'zod';

const DataUsageSchema = z.object({
  bytes: z.number(),
  megaBytes: z.number(),
  gigaBytes: z.number(),
  displayValue: z.string(),
});

const ConsumptionSchema = z.object({
  smsSent: z.number(),
  mmsSent: z.number(),
  callsMade: z.number(),
  callSeconds: z.number(),
  cpaUsageNok: z.number(),
  dataUsage: DataUsageSchema,
});

export const historicalConsumptionTelenorSchema = z.object({
  year: z.number(),
  month: z.number(),
  roaming: ConsumptionSchema.omit({ cpaUsageNok: true }),
  domestic: ConsumptionSchema,
});

export const historicalConsumptionTelenorItemsSchema = z.object({
  items: z.array(historicalConsumptionTelenorSchema),
});

export type HistoricalConsumptionTelenor = z.infer<typeof historicalConsumptionTelenorSchema>;
export type HistoricalConsumptionTelenorItems = z.infer<
  typeof historicalConsumptionTelenorItemsSchema
>;
