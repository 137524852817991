import type { ReactNode } from 'react';
import { createContext, useState } from 'react';

interface DemoContextProps {
  isDemoMode: boolean;
  toggleDemoMode: () => void;
}

interface Props {
  children: ReactNode;
}

const DemoContext = createContext<DemoContextProps>({} as DemoContextProps);

const DemoModeProvider = ({ children }: Props) => {
  const [isDemoMode, setIsDemoMode] = useState(false);

  const toggleDemoMode = () => {
    setIsDemoMode(prev => !prev);
  };

  return (
    <DemoContext.Provider value={{ isDemoMode, toggleDemoMode }}>{children}</DemoContext.Provider>
  );
};

export { DemoContext, DemoModeProvider };
