import { z } from 'zod';

const dataPlanSchema = z.object({
  dataPlanId: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  name: z.string(),
  fullName: z.string(),
  description: z.string().nullable(),
  carrier: z.enum(['Telia', 'Telenor']),
  includedByteDataLimit: z.number(),
  underlyingByteDataLimit: z.number(),
  customerMonthlyPrice: z.number(),
  overUsageGigabytePrice: z.number(),
  setupPrice: z.number(),
  isDisabled: z.boolean(),
  isFavorite: z.boolean(),
  // subscriptionType: z.string(), THIS IS AN OBJECT AND NOT A STRING NOW, BUT SINCE WE DON'T USE IT, WE CAN IGNORE IT
  subscriptionCategoryType: z.enum(['COMLINE', 'DATA', 'IOT', 'VOICE']),
  apnNetworkConfig: z.string(),
  tenantId: z.string().nullable(),
  currency: z.string(),
  sellingPoints: z.array(
    z.object({
      text: z.string(),
    }),
  ),
});

export const dataPlansSchema = z.object({
  items: z.array(dataPlanSchema),
});

export type DataPlanDto = z.infer<typeof dataPlanSchema>;
export type DataPlansDto = z.infer<typeof dataPlansSchema>;
