import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { Button, Grid, Icon } from '@intility/bifrost-react';
import { Link } from 'react-router-dom';

import { SubscriptionCategoryType } from '@/features/subscriptions/api';
import { routes } from '@/routes/config';
import type { SubscriptionProductType } from '../types/products';

interface OtherProductsButtonsProps {
  category: SubscriptionProductType | 'all';
}

export const OtherProductsButtons = ({ category }: OtherProductsButtonsProps) => {
  return (
    <div>
      <p className={'bf-p bf-large !mx-0 !mt-0 mb-bfs-4'}>Se våre andre abonnement</p>

      <Grid small={3}>
        {[
          ['Mobilabonnement', SubscriptionCategoryType.VOICE],
          ['Mobilt bredbånd', SubscriptionCategoryType.DATA],
          ['IoT-abonnement', SubscriptionCategoryType.IOT],
          ['Se alle abonnementer', 'all'],
        ].map(([buttonText, subCategory]) => {
          return category === subCategory ? undefined : (
            <Link
              to={`${routes.products.overview.path}/${subCategory}`}
              className='flex'
              key={subCategory}
            >
              <Button state='neutral' className='grow' key={subCategory}>
                {buttonText}
                <Icon icon={faAngleRight} marginLeft />
              </Button>
            </Link>
          );
        })}
      </Grid>
    </div>
  );
};
