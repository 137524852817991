import { z } from 'zod';

const billingCompanySchema = z.object({
  id: z.number(),
  billingCompany: z.string(),
});

export const billingCompaniesSchema = z.object({
  items: z.array(billingCompanySchema),
});

export type BillingCompanyDto = z.infer<typeof billingCompanySchema>;
