import { faFileContract } from '@fortawesome/pro-regular-svg-icons';
import type { ReactNode } from 'react';

import { FullscreenMessage } from '@/components/FullscreenMessage';
import { lazyImport } from '@/utils/lazyImport';
import { getLoggedInUserOid } from '@/utils/msal';
import { type TermsAgreementDto } from '../api/getTermsAgreement.schema';

interface TermsNotAgreedProps {
  agreementStatus: TermsAgreementDto;
  children?: ReactNode;
}

const { Terms } = lazyImport(() => import('@/features/terms-of-service/routes/Terms'), 'Terms');

export const TermsNotAgreed = ({ agreementStatus, children }: TermsNotAgreedProps) => {
  const userOid = getLoggedInUserOid();

  const isLegalPerson = agreementStatus.legalContactPersons.some(
    legalPerson => legalPerson.entraObjectId === userOid,
  );

  const isTosOutdated =
    Boolean(agreementStatus.tosAcceptedAt) && !agreementStatus.hasAcceptedLatestTosDocument;

  const legalPeopleNames = agreementStatus.legalContactPersons.map(
    legalPerson => legalPerson.fullName,
  );

  return (
    <>
      {isLegalPerson ? (
        <Terms />
      ) : isTosOutdated ? (
        <>{children}</>
      ) : (
        <FullscreenMessage textAlign='left' height='calc(100vh - var(--bf-nav-top-height))'>
          <FullscreenMessage.Icon icon={faFileContract} size={'3x'} color='var(--bfc-theme)' />
          <FullscreenMessage.Header>Manglende godkjenning av vilkår</FullscreenMessage.Header>
          <FullscreenMessage.Paragraph>
            Ditt firma har ikke godtatt vilkårene for bruk av denne applikasjonen. Ta kontakt med{' '}
            {legalPeopleNames.map((name, index) => (
              <>
                <span key={name} className='bfc-theme'>
                  {name}
                </span>
                {index !== legalPeopleNames.length - 1 ? ', ' : ' '}
              </>
            ))}
            for godkjennelse og aktivering.
          </FullscreenMessage.Paragraph>
        </FullscreenMessage>
      )}
    </>
  );
};
