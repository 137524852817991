export const formatPrice = (amount: number, options?: Intl.NumberFormatOptions) => {
  const formatter = new Intl.NumberFormat('nb-NO', {
    currency: 'NOK',
    maximumFractionDigits: 2,
    style: 'currency',
    ...options,
  });

  return formatter.format(amount).replace(/\D00(?=\D*$)/, '');
};

export const formatUsage = (gigabytes: number) => {
  const formatter = new Intl.NumberFormat('no-NO', {
    style: 'unit',
    unit: 'gigabyte',
    maximumFractionDigits: 2,
  });

  return formatter.format(gigabytes);
};

export const cleanName = (name: string) => {
  return name.split('/')[0].trim();
};

export const formatPercentage = (quotient: number, options?: Intl.NumberFormatOptions) => {
  const percentageFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  });

  return percentageFormatter.format(quotient);
};

export const formatMsisdn = (msisdn: string): string => {
  if (msisdn.length === 12) {
    return msisdn.replace(/\B(?=(\d{4})+(?!\d))/g, ' ');
  } else if (msisdn.length === 8) {
    return msisdn.replace(/(\d{2})(\d{2})(\d{2})(\d{2})$/, '$1 $2 $3 $4');
  } else {
    return msisdn;
  }
};
