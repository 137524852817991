// https://gitlab.intility.com/DeveloperServices/portal2/portal-frontend/-/blob/master/src/auth/Authentication.tsx

import type { AuthenticationResult } from '@azure/msal-browser';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { instance } from '@/auth';
import { loginHint } from '@/auth/instance';
import { HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY } from '@/types/constants';

export const loginRequest = {
  scopes: ['.default'],
  loginHint: loginHint,
};

const hasTriedInteractive = sessionStorage.getItem(HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY);

const Template = ({ children }: PropsWithChildren) => {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

  // if error is InteractionRequired, we will automatically try an interactive login
  // only try it automatically once per session
  const shouldTryInteractiveLogin =
    error instanceof InteractionRequiredAuthError && !hasTriedInteractive;

  useEffect(() => {
    if (shouldTryInteractiveLogin) {
      login(InteractionType.Redirect, {
        ...loginRequest,
        redirectUri: window.location.origin,
      });

      // will update hasTriedInteractive once the user is redirected back and load the page again
      sessionStorage.setItem(HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY, 'true');
    }
  }, [shouldTryInteractiveLogin, login]);

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

const Authentication = ({ children }: PropsWithChildren) => {
  return <Template>{children}</Template>;
};

export const useToken = (scope: string[]) => {
  const [token, setToken] = useState<AuthenticationResult | null>(null);

  useEffect(() => {
    const activeAccount = instance.getActiveAccount();

    if (activeAccount) {
      instance.acquireTokenSilent({ scopes: scope, account: activeAccount }).then(setToken);
    }
  }, []);

  return token;
};

export default Authentication;
