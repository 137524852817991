import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon, Input } from '@intility/bifrost-react';

interface CounterProps {
  count: number;
  onInputChange: (quantity: number) => void;
}

export const Counter = ({ count, onInputChange }: CounterProps) => {
  const handleOnChange = (value: string) => {
    const quantity = Number(value);

    onInputChange(quantity);
  };

  return (
    <div className='flex max-w-[158px] items-center gap-bfs-8 p-0'>
      <Button
        data-testid='btn-decrement'
        className='border border-bfc-base-c-wcag bg-bfc-base-3 hover:border-bfc-base-c'
        pill
        noPadding
        small
        onClick={() => onInputChange(count - 1)}
      >
        <Icon icon={faMinus} className='text-bfc-base-c-alert' />
      </Button>
      <Input
        onChange={e => handleOnChange(e.target.value.replace(/^0+/g, ''))}
        min={'1'}
        type={'number'}
        inputClassName={'text-center rounded-lg'}
        hideLabel
        label={'Antall'}
        value={count}
        className='bf-strong'
      />
      <Button
        data-testid='btn-increment'
        className='border border-bfc-base-c-wcag bg-bfc-base-3 hover:border-bfc-base-c'
        pill
        noPadding
        onClick={() => onInputChange(count + 1)}
        small
      >
        <Icon icon={faPlus} className='text-bfc-base-c-success' />
      </Button>
    </div>
  );
};
