import { z } from 'zod';

import { userRoles } from './getUsersAccessRole.schema';

export const myUserSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  userPrincipalName: z.string(),
  role: z.enum(userRoles).nullable(),
});

export type MyUserDto = z.infer<typeof myUserSchema>;
