import type { ReactNode } from 'react';
import type { NavLinkProps as RouterNavLinkProps } from 'react-router-dom';
import { NavLink as RouterNavLink } from 'react-router-dom';

type NavLinkProps = Omit<RouterNavLinkProps, 'to' | 'children'> & {
  /** The url path - absolute paths (starting with '/') will automatically create a link including the current locale */
  to: string;
  /** Clickable link text (or other content) */
  children: ReactNode;
};

export const NavLink = ({ to, children, ...props }: NavLinkProps) => {
  return (
    <RouterNavLink {...props} to={to}>
      {children}
    </RouterNavLink>
  );
};
