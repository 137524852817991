import type { Configuration, EventMessage } from '@azure/msal-browser';
import { EventType, PublicClientApplication } from '@azure/msal-browser';

import { HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY } from '@/types/constants';
import { env } from '@/utils/env';

const AUTHORITY = 'https://login.microsoftonline.com/common';

const config: Configuration = {
  auth: {
    clientId: env.portalAppId,
    authority: AUTHORITY,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

const loginHintKey = 'login_hint';

// only get once on page load
export const loginHint = localStorage.getItem(loginHintKey) ?? undefined;

/**
 * A PublicClientApplication instance
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const instance = new PublicClientApplication(config);

if (!instance.getActiveAccount()) {
  const accounts = instance.getAllAccounts();

  if (accounts.length) {
    const account = accounts[0];
    instance.setActiveAccount(account);
    localStorage.setItem(loginHintKey, account.username);
  }
}

/**
 * Register event callback to set the active account atfer successful login
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/accounts.md#active-account-apis
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
 */
instance.addEventCallback((message: EventMessage) => {
  if (
    message.eventType === EventType.LOGIN_SUCCESS ||
    message.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    if (message.payload && 'account' in message.payload) {
      const account = message.payload.account;

      if (account) {
        instance.setActiveAccount(account);
        localStorage.setItem(loginHintKey, account.username);
        localStorage.removeItem(HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY);
      }
    }
  }
});

export { instance };
