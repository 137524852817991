import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { faFaceFrown, faFaceViewfinder } from '@fortawesome/pro-regular-svg-icons';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';

import { instance } from '@/auth';
import { API } from '@/auth/config';
import { FullscreenMessage } from '@/components/FullscreenMessage';
import { useCreateMyUser } from '@/features/user-management/api';
import { type AzureToken } from '@/types/azure';

// To-do: import from sim-utils
const appRoles = ['Api.Customer.Admin', 'Api.Customer.Approver', 'Api.Customer.User'] as const;

export const CreateUser = () => {
  const { mutateAsync, isError } = useCreateMyUser();

  /**
   * Create a user and wait some seconds for Azure to assign the role.
   */
  useEffect(() => {
    const createUser = async () => {
      const tokenRequest = {
        scopes: [API.Sim.scope],
        account: instance.getActiveAccount() ?? undefined,
      };

      mutateAsync()
        .then(async () => {
          let roleApplied = false;
          let attempts = 0; // 3s delay per attempt

          while (!roleApplied) {
            if (attempts >= 3) {
              roleApplied = true;
              break;
            }

            await new Promise(resolve => setTimeout(resolve, 3000));

            for (const key in localStorage) {
              if (key.toLowerCase().includes('windows.net-accesstoken')) {
                localStorage.removeItem(key);
              }
            }

            const token = await instance.acquireTokenSilent({
              ...tokenRequest,
              redirectUri: `${window.location.origin}/auth.html`,
            });

            if (token.accessToken) {
              const decodedToken = jwtDecode<AzureToken>(token.accessToken);

              for (const tokenRole of decodedToken.roles ?? []) {
                if (appRoles.includes(tokenRole)) {
                  roleApplied = true;
                }
              }
            }

            attempts += 1;
          }

          window.location.reload();
        })
        .catch(async error => {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              ...tokenRequest,
              redirectUri: window.location.origin,
            });
          }
        });
    };

    createUser();
  }, []);

  return (
    <FullscreenMessage height='calc(100vh - var(--bf-nav-top-height))' width=''>
      <FullscreenMessage.Icon
        icon={isError ? faFaceFrown : faFaceViewfinder}
        size={'3x'}
        color='var(--bfc-theme)'
        spinningIcon={!isError}
      />
      <FullscreenMessage.Header>Manglende rettigheter</FullscreenMessage.Header>
      <FullscreenMessage.Paragraph>
        {isError
          ? 'Det oppstod en feil ved tilegning av rettigheter. Prøv på nytt eller kontakt Intility for mer informasjon hvis problemet vedvarer.'
          : 'Vennligst vent mens du tilegnes rettigheter...'}
      </FullscreenMessage.Paragraph>
    </FullscreenMessage>
  );
};
