import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle as fasFaExclamationTriangle,
  faGlobe as fasFaGlobe,
  faPhoneLaptop as fasFaPhoneLaptop,
  faRouter as fasFaRouter,
  faServer as fasFaServer,
  faShieldAlt as fasFaShieldAlt,
  faSignal as fasFaSignal,
  faUser as fasFaUser,
  faWifi as fasFaWifi,
} from '@fortawesome/pro-solid-svg-icons';

import { intilityIcon } from '@/assets/intilityIcon';

const farIcons: Array<IconDefinition | never> = [];

const fasIcons: Array<IconDefinition | never> = [
  fasFaServer,
  fasFaGlobe,
  fasFaShieldAlt,
  fasFaWifi,
  fasFaSignal,
  fasFaPhoneLaptop,
  fasFaRouter,
  fasFaExclamationTriangle,
  fasFaUser,
];

const falIcons: Array<IconDefinition | never> = [];

const allIcons = [...farIcons, ...fasIcons, ...falIcons, intilityIcon];

library.add(...allIcons);

export default function registerIcons(): void {
  library.add(...allIcons);
}
