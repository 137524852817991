import { create } from 'zustand';

import { type BasketItem } from '@/features/order/types';
import { type DataPlanDto } from '@/features/products/api';

interface State {
  basket: BasketItem[];
  actions: {
    addToBasket: (product: DataPlanDto, quantity?: number) => void;
    removeFromBasket: (dataPlanUuid: string, quantity?: number) => void;
    removePresetsFromBasket: (dataPlanUuid: string) => void;
    emptyBasket: () => void;
  };
}

const basketStore = create<State>(set => ({
  basket: [],
  actions: {
    addToBasket: (product: DataPlanDto, quantity) =>
      set(state => {
        const basketItem = state.basket.find(item => item.dataPlanUuid === product.dataPlanId);

        if (basketItem) {
          return {
            basket: state.basket.map(item =>
              item.dataPlanUuid === product.dataPlanId
                ? { ...item, amount: quantity ?? item.amount + 1 }
                : item,
            ),
          };
        }

        return {
          basket: [
            ...state.basket,
            {
              dataPlanUuid: product.dataPlanId,
              amount: quantity ?? 1,
              product: product,
            },
          ],
        };
      }),
    removeFromBasket: (dataPlanUuid: string, quantity?: number) =>
      set(state => {
        const basketItem = state.basket.find(item => item.dataPlanUuid === dataPlanUuid);
        if (basketItem) {
          if (basketItem.amount === 1) {
            return {
              basket: state.basket.filter(item => item.dataPlanUuid !== dataPlanUuid),
            };
          } else {
            return {
              basket: state.basket.map(item =>
                item.dataPlanUuid === dataPlanUuid
                  ? { ...item, amount: quantity ?? item.amount - 1 }
                  : item,
              ),
            };
          }
        }

        return {
          basket: state.basket,
        };
      }),
    removePresetsFromBasket: (dataPlanUuid: string) =>
      set(state => ({
        basket: state.basket.filter(item => item.dataPlanUuid !== dataPlanUuid),
      })),
    emptyBasket: () => set(() => ({ basket: [] })),
  },
}));

export const useBasket = () => basketStore(state => state.basket);

export const useBasketActions = () => basketStore(state => state.actions);
