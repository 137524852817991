import { SubscriptionCategoryType } from '@/features/subscriptions/api';
import { type DataPlanDto } from '../api/getDataPlans.schema';
import type { SubscriptionProductType } from '../types/products';

export const categorizeDataPlans = (dataPlans: DataPlanDto[] | undefined) => {
  const categorizedDataPlans: Record<SubscriptionProductType, DataPlanDto[]> = {
    VOICE: [],
    DATA: [],
    IOT: [],
  };

  for (const dataPlan of dataPlans ?? []) {
    if (dataPlan.subscriptionCategoryType === SubscriptionCategoryType.VOICE) {
      categorizedDataPlans.VOICE.push(dataPlan);
    } else if (dataPlan.subscriptionCategoryType === SubscriptionCategoryType.DATA) {
      categorizedDataPlans.DATA.push(dataPlan);
    } else if (dataPlan.subscriptionCategoryType === SubscriptionCategoryType.IOT) {
      categorizedDataPlans.IOT.push(dataPlan);
    }
  }
  //Display "firmaavtale" plans first in the list
  Object.entries(categorizedDataPlans).forEach(([key, plans]) => {
    categorizedDataPlans[key as SubscriptionProductType] = plans.sort((a, b) => {
      if (a.tenantId === null) return 1;
      if (b.tenantId === null) return -1;
      return a.tenantId > b.tenantId ? 1 : -1;
    });
  });

  return categorizedDataPlans;
};
