import { faBuilding, faUser } from '@fortawesome/pro-regular-svg-icons';
import { Checkbox, Icon, Nav, Spinner } from '@intility/bifrost-react';
import { useContext } from 'react';

import { DemoContext } from '@/context/DemoContext';
import { ModalContext } from '@/context/ModalContext';
import { useGraph } from '@/lib/graph';
import { cn } from '@/utils/clsx';
import { env } from '@/utils/env';

export const TopNav = () => {
  const appVersion = env.version;
  const isStaging = window.location.hostname.includes('sim-staging');
  const versionText = !isStaging ? `v${appVersion}` : `v${appVersion} - Staging`;
  const { handleModal } = useContext(ModalContext);
  const { isDemoMode, toggleDemoMode } = useContext(DemoContext);

  return (
    <>
      {/* <div className={'block md:hidden'}>
        <Nav.Item
          onClick={() =>
            handleModal(<UserFeedback isModal={true} />, 'Hvordan liker du Intility SIM?')
          }
          className={'cursor-pointer hover:[&>svg]:text-bfc-theme'}
          icon={faCommentSmile}
        />
      </div> */}
      <Nav.Group name={<ProfilePicture small />} title='User profile'>
        <div className={'pr-4'}>
          <div className={'pb-4 pl-4'}>
            <UserDropdown />
            {appVersion && <span className='leading-8'>{versionText}</span>}
          </div>
          <Checkbox
            button
            label='Demo modus'
            checked={isDemoMode}
            onChange={() => toggleDemoMode()}
          />
        </div>
      </Nav.Group>
    </>
  );
};

interface ProfilePictureProps {
  small?: boolean;
}

const ProfilePicture = ({ small }: ProfilePictureProps) => {
  const { data, isLoading, isError } = useGraph();
  const { isDemoMode } = useContext(DemoContext);
  const userImgUrl = data?.userImgUrl;
  const userInfo = data?.userInfo;

  return (
    <div className={cn(`cursor-pointer align-baseline`, isDemoMode && 'blur-sm')}>
      {isLoading ? (
        <Spinner
          data-testid={'loading-spinner'}
          className={`${small ? '' : 'p-bfs-24'}`}
          size={small ? 14 : 70}
        />
      ) : isError || !userImgUrl ? (
        <Icon data-testid={'user-icon'} icon={'user'} />
      ) : userImgUrl ? (
        <img
          className='h-[28px] w-[28px] rounded-[50%]'
          src={userImgUrl}
          alt={userInfo?.givenName ?? 'user image'}
        />
      ) : null}
    </div>
  );
};

const UserDropdown = () => {
  const { data } = useGraph();

  const userInfo = data?.userInfo;

  return (
    <div className={'flex cursor-default flex-col pr-bfs-24'}>
      <span className='leading-8'>
        <Icon icon={faUser} /> {userInfo?.givenName} {userInfo?.surname}
      </span>

      <span className='leading-8'>
        <Icon icon={faBuilding} /> {userInfo?.companyName}
      </span>
    </div>
  );
};
