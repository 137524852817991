import { Button, Grid } from '@intility/bifrost-react';
import { Link } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { SubscriptionCategoryType } from '@/features/subscriptions/api';
import { routes } from '@/routes/config';
import { ProductCategoryOverviewCard } from '../components/ProductCategoryOverviewCard';
import type { SubscriptionProductType } from '../types/products';

export const ProductCategoriesOverview = () => {
  const subscriptionCategories: SubscriptionProductType[] = [
    SubscriptionCategoryType.VOICE,
    SubscriptionCategoryType.DATA,
    SubscriptionCategoryType.IOT,
  ];

  return (
    <div
      className={'h-full bg-products-light bg-cover bg-center bg-no-repeat dark:bg-products-dark'}
    >
      <ContentLayout title='Bestill abonnement' className='max-w-6xl'>
        <h1 className='bf-h1 mt-0'>Velg ditt abonnement</h1>
        <p className='bf-p mb-bfs-24 mt-bfs-16'>
          Hos oss kan du velge abonnement tilpasset dine enheter og forbruk
        </p>

        <Grid small={2} medium={3}>
          {subscriptionCategories.map(category => (
            <ProductCategoryOverviewCard key={category} category={category} />
          ))}
        </Grid>

        <div className='mt-bfs-16 text-center'>
          <Link to={`${routes.products.overview.path}/all`}>
            <Button state='neutral' variant='flat' className={'bf-link'}>
              Se alle abonnement
            </Button>
          </Link>
        </div>
      </ContentLayout>
    </div>
  );
};
