import { z } from 'zod';

import { Carrier } from './getBusinessSubscriptions.schema';

const serviceMappingSchema = z.object({
  serviceMappingId: z.string(),
  code: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  isDisabled: z.boolean(),
  isSelectable: z.boolean(),
  carrier: z.nativeEnum(Carrier),
  serviceMappingType: z.enum(['SERVICE', 'BARRING']),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type ServiceMappingDto = z.infer<typeof serviceMappingSchema>;

const subscriptionServiceSchema = z.object({
  subscription: z.string(),
  createdAt: z.string().datetime(),
  serviceMapping: serviceMappingSchema,
});

export type SubscriptionServiceDto = z.infer<typeof subscriptionServiceSchema>;

export const subscriptionServicesSchema = z.object({
  items: z.array(subscriptionServiceSchema),
});
