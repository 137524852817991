import { z } from 'zod';

const legalContactPersonSchema = z.object({
  fullName: z.string(),
  entraObjectId: z.string(),
});

export const termsAgreementSchema = z.object({
  companyId: z.string(),
  publishTosDocument: z.object({
    publishTosDocumentId: z.string(),
    title: z.string(),
    invalidatedAt: z.string().datetime().nullable(),
  }),
  hasAcceptedLatestTosDocument: z.boolean(),
  tosAcceptedAt: z.string().datetime().nullable(),
  tosAcceptedByEntraObjectId: z.string().nullable(),
  tosRevokedAt: z.string().datetime().nullable(),
  tosRevokedByEntraObjectId: z.string().nullable(),
  legalContactPersons: z.array(legalContactPersonSchema),
});

export type TermsAgreementDto = z.infer<typeof termsAgreementSchema>;
