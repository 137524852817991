import type { ReactNode } from 'react';

import { FullscreenMessage } from '@/components/FullscreenMessage';
import { useTermsAgreement } from '../api/getTermsAgreement';
import { TermsNotAgreed } from './TermsNotAgreed';

interface TermsValidatorProps {
  children?: ReactNode;
}

export const TermsValidator = ({ children }: TermsValidatorProps) => {
  const agreementStatus = useTermsAgreement();

  const termsAreAgreed = agreementStatus.data?.hasAcceptedLatestTosDocument;

  if (agreementStatus.isError) {
    return (
      <FullscreenMessage>
        <FullscreenMessage.Header>Feil ved sjekk av bruksvilkår</FullscreenMessage.Header>
        <FullscreenMessage.Paragraph>
          Det oppstod en feil ved sjekk av bruksvilkår. Vennligst kontakt Intility support.
        </FullscreenMessage.Paragraph>
      </FullscreenMessage>
    );
  }

  if (agreementStatus.isPending) return <></>;
  if (termsAreAgreed) return <>{children}</>;

  return <TermsNotAgreed agreementStatus={agreementStatus.data}>{children}</TermsNotAgreed>;
};
