import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@intility/bifrost-react';
import { Link, useLocation } from 'react-router-dom';
import type { ReactElement } from 'react';

import { routes } from '@/routes/config';
import { useBasket } from '@/stores/basket';

export const BottomBar = (): ReactElement | null => {
  const basket = useBasket();

  const currentPath = useLocation();
  const daniKurvAmount = basket.reduce(
    (totalBasketAmount, basketItem) => totalBasketAmount + basketItem.amount,
    0,
  );

  if (currentPath.pathname.includes('/order') || daniKurvAmount === 0) return null;

  return (
    <div className='mt-bfs-40'>
      <footer
        style={{
          boxShadow: '0 -4px 8px rgb(0 0 0 / 10%)',
          marginLeft: 'var(--bf-nav-side-width)',
          transition: 'margin var(--bf-nav-side-transition)',
        }}
        className='fixed bottom-0 left-0 right-0 z-[9999] flex h-bfs-56 items-center bg-bfc-base-3 p-bfs-16 py-bfs-8 sm:px-bfs-40'
      >
        <div className='flex w-full items-center justify-end'>
          <p className='bf-p my-0 mr-bfs-12'>{daniKurvAmount} abonnement under bestilling</p>

          <Link to={routes.order.basketSummary.path}>
            <Button small rightIcon icon={faArrowRight} variant='filled'>
              Sammendrag
            </Button>
          </Link>
        </div>
      </footer>
    </div>
  );
};
