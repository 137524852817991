import { faAngleRight, faCheck } from '@fortawesome/pro-light-svg-icons';
import { Button, Card, Grid, Icon, Message, Skeleton } from '@intility/bifrost-react';
import { Link } from 'react-router-dom';

import { routes } from '@/routes/config';
import { useDataPlans } from '../api/getDataPlans';
import { categoryMetadata } from '../assets/metadata';
import type { CategoryMetadata, SubscriptionProductType } from '../types/products';

interface ProductCategoryOverviewCardProps {
  category: SubscriptionProductType;
}

export const ProductCategoryOverviewCard = ({ category }: ProductCategoryOverviewCardProps) => {
  const dataPlans = useDataPlans();

  const categoryInfo = categoryMetadata[category];
  const dataPlanPrices: number[] = [];

  for (const dataPlan of dataPlans.data?.items ?? []) {
    if (dataPlan.subscriptionCategoryType !== category) continue;

    dataPlanPrices.push(dataPlan.customerMonthlyPrice);
  }

  const cheapestDataPlan = Math.min(...dataPlanPrices);

  return (
    <Card align='center' className='bf-padding rounded-xl border border-bfc-base-c-dimmed'>
      <Grid gap={24} className='h-full'>
        <CategoryHeader categoryMetadata={categoryInfo} />
        <CategorySellingPoints categoryMetadata={categoryInfo} />
        <Grid className='self-end'>
          {dataPlans.isError && (
            <Message state='alert'>
              Kunne ikke hente abonnementer. Kontakt Intility hvis problemet vedvarer.
            </Message>
          )}
          {dataPlans.isPending && (
            <Skeleton>
              <Skeleton.Text data-testid='loading-skeleton' />
            </Skeleton>
          )}
          {dataPlans.data && (
            <p className='bf-label'>
              {dataPlanPrices.length ? `fra ${cheapestDataPlan}kr/mnd` : 'Kontakt oss for priser'}
            </p>
          )}

          <Link to={`${routes.products.overview.path}/${category.toLowerCase()}`}>
            <Button state='neutral' variant='filled' className='w-full'>
              {categoryInfo.buttonText}
              <Icon marginLeft icon={faAngleRight} />
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

const CategoryHeader = ({ categoryMetadata }: { categoryMetadata: CategoryMetadata }) => {
  const { categoryHeader, categoryIngress } = categoryMetadata;

  return (
    <div>
      <h4 className='font-display text-xl font-bold'>{categoryHeader}</h4>
      <p className='bf-label-description text-left'>{categoryIngress}</p>
    </div>
  );
};

const CategorySellingPoints = ({ categoryMetadata }: { categoryMetadata: CategoryMetadata }) => {
  const { sellingPoints } = categoryMetadata;

  return (
    <ul className='flex list-none flex-col gap-bfs-8 text-left align-top'>
      {sellingPoints.map(sellingPoint => (
        <li key={sellingPoint} className='flex'>
          <Icon icon={faCheck} className='mr-bfs-12 mt-bfs-4' color='var(--bfc-theme)' />
          <p className={'bf-p bfc-base'}>{sellingPoint}</p>
        </li>
      ))}
    </ul>
  );
};
