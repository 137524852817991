import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomNavigationClient, instance } from '@/auth';
import { useEvent } from '@/hooks/useEvent';

/**
 * Configures MSAL to use react-router's navigation.
 * Prevents MSAL from doing full reloads when navigating.
 */
export const useMsalRouterIntegration = () => {
  const navigate = useEvent(useNavigate());

  useEffect(() => {
    instance.setNavigationClient(new CustomNavigationClient(navigate));
  }, [navigate]);
};
