import { faAngleUp, faList } from '@fortawesome/pro-regular-svg-icons';
import { Button, Drawer, FloatingArea, Grid, Icon, Sticky } from '@intility/bifrost-react';
import React, { useState } from 'react';

import { cn } from '@/utils/clsx';

export const TableOfContents = ({
  children,
  header = 'På denne siden',
  stickyClassName,
}: {
  children: React.ReactNode;
  header?: string;
  stickyClassName?: string;
}) => {
  const [expanded, setExpanded] = useState(false);

  const contents = (
    <Grid>
      <Grid className='border-b border-bfc-base-dimmed p-bfs-16'>
        <h5 className='bfc-base-2 bf-medium bf-strong'>{header}</h5>
        <ul className='bf-toc bf-scrollbar-small'>{children}</ul>
      </Grid>

      <button
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
        className={'bf-title-link !px-bfs-16 !pb-bfs-12 !text-sm'}
      >
        Til toppen av siden <Icon className='ml-bfs-8' icon={faAngleUp} />
      </button>
    </Grid>
  );

  return (
    <>
      <Sticky className={cn('from-medium rounded-lg p-bfs-0', stickyClassName)}>{contents}</Sticky>

      <FloatingArea className='to-medium'>
        <Button pill onClick={() => setExpanded(true)} className='bf-shadow'>
          Innhold <Icon fixedWidth marginLeft icon={faList} />
        </Button>
      </FloatingArea>

      <Drawer
        position='bottom'
        className='to-medium'
        header={header}
        isOpen={expanded}
        onRequestClose={() => setExpanded(false)}
      >
        {contents}
      </Drawer>
    </>
  );
};
