import type { PropsWithChildren } from 'react';
import { Component, lazy, Suspense } from 'react';

import { ErrorPage } from './ErrorPage';

const SentryErrorBoundary = lazy(() => import('./SentryErrorBoundary'));

class InternalErrorBoundary extends Component<PropsWithChildren, { hasError: boolean }> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage resetError={() => this.setState({ hasError: false })} />;
    }

    return this.props.children;
  }
}

const ErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <InternalErrorBoundary>
      <Suspense fallback={children}>
        <SentryErrorBoundary fallback={({ resetError }) => <ErrorPage resetError={resetError} />}>
          {children}
        </SentryErrorBoundary>
      </Suspense>
    </InternalErrorBoundary>
  );
};

export default ErrorBoundary;
