import { z } from 'zod';

import { Carrier } from './getBusinessSubscriptions.schema';

const encodedQrCodeSchema = z.object({
  data: z.string(),
  encoding: z.literal('base64'),
  mediaType: z.literal('image/gif'),
});

const telenorQrCodeSchema = z.object({
  qrCode: z.object({
    url: z.string().nullable(),
    encoded: encodedQrCodeSchema,
  }),
  carrier: z.literal(Carrier.TELENOR),
});

const teliaQrCodeSchema = z.object({
  qrCode: z.object({
    url: z.string(),
    encoded: encodedQrCodeSchema.nullable(),
  }),
  carrier: z.literal(Carrier.TELIA),
});

const qrCodeSchema = z.discriminatedUnion('carrier', [telenorQrCodeSchema, teliaQrCodeSchema]);

export const esimActivationProfileSchema = z
  .object({
    simId: z.string(),
    activationCode: z.string(),
  })
  .and(qrCodeSchema);

export type EsimActivationProfileDto = z.infer<typeof esimActivationProfileSchema>;
