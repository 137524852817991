import type { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import type { IconDefinition, IconName } from '@fortawesome/pro-regular-svg-icons';

export const intilityIcon: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'intility' as IconName,
  icon: [
    80,
    89,
    [],
    'e001',
    'M9.99905 6.23097C10.0015 4.99697 10.3729 3.79137 11.0663 2.76642C11.7597 1.74148 12.7441 0.943171 13.8951 0.472329C15.046 0.00148692 16.312 -0.120769 17.5331 0.121002C18.7541 0.362773 19.8755 0.957728 20.7555 1.83072C21.6356 2.70371 22.2349 3.81558 22.4776 5.02589C22.7204 6.23621 22.5958 7.49068 22.1196 8.63086C21.6434 9.77104 20.8369 10.7458 19.8019 11.432C18.767 12.1182 17.5501 12.485 16.3049 12.4863C15.4759 12.4879 14.6546 12.3271 13.8884 12.0132C13.1222 11.6994 12.4262 11.2386 11.8402 10.6573C11.2543 10.0761 10.79 9.38581 10.474 8.6262C10.158 7.86658 9.99662 7.05259 9.99905 6.23097Z M47.2872 38.334H39V28.4131H47.2872V17.3494H60.0155V28.4131H79.7429V38.334H60.0155V59.9813C60.0155 65.9671 61.0379 70.2224 63.0826 72.7472C65.1273 75.272 68.3988 76.5344 72.8972 76.5344H79.7429V88.0845C74.4737 88.0845 73.8296 88.0845 67.536 88.0845C64.8228 88.1245 62.133 87.5823 59.6512 86.4952C57.1693 85.4082 54.9541 83.8019 53.1576 81.7867C49.2522 77.6084 47.2954 72.3278 47.2872 65.9448V38.334Z M9.86368 88.2V39.0392H0V28.4131H22.5981V88.2H9.86368Z',
  ],
};
