import { env } from '@/utils/env';

const {
  simApiUrl: intilitySimApiUrl,
  simApiAppId: intilitySimApiAppId,
  consumptionApiUrl,
  consumptionApiAppId,
  usersApiUrl,
  usersApiAppId,
  notifyApiUrl,
  notifyApiAppId,
  publishApiUrl,
} = env;

interface ApiDetails {
  url: string;
  scope: string;
}

export const API = {
  Sim: {
    url: intilitySimApiUrl,
    scope: `api://${intilitySimApiAppId}/Api.access`,
  },
  Users: {
    url: usersApiUrl,
    scope: `api://${usersApiAppId}/Api.access`,
  },
  Consumption: {
    url: consumptionApiUrl,
    scope: `api://${consumptionApiAppId}/user_impersonation`,
  },
  Graph: {
    url: 'https://graph.microsoft.com',
    scope: 'User.Read',
  },
  Publish: {
    url: publishApiUrl,
    scope: 'api://dd2c1288-e484-49ba-9eef-04358bc22dec/user_impersonation',
  },
  Notify: {
    url: notifyApiUrl,
    scope: `api://${notifyApiAppId}/Api.Access`,
  },
} as const satisfies Record<string, ApiDetails>;
