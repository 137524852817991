import { Navigate, Route, Routes } from 'react-router-dom';

import { lazyImport } from '@/utils/lazyImport';

const { OrderSummary } = lazyImport(() => import('./OrderSummary'), 'OrderSummary');
const { BasketSummary } = lazyImport(() => import('./BasketSummary'), 'BasketSummary');
const { OrderCheckout } = lazyImport(() => import('./OrderCheckout'), 'OrderCheckout');
const { OrderShippingSummary } = lazyImport(
  () => import('./ShippingSummary'),
  'OrderShippingSummary',
);

export const OrderRoutes = () => {
  return (
    <Routes>
      <Route path='basket' element={<BasketSummary />} />
      <Route path='checkout' element={<OrderCheckout />} />
      <Route path='shipping' element={<OrderShippingSummary />} />
      <Route path='summary' element={<OrderSummary />} />
      <Route path='*' element={<Navigate to='basket' />} />
    </Routes>
  );
};
