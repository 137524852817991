import { faRedo } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Message } from '@intility/bifrost-react';
import type { UseQueryResult } from '@tanstack/react-query';

interface ErrorboxProps {
  queries?: UseQueryResult<unknown, unknown>[];
  errorMessage?: string;
}

export const Errorbox = ({ queries, errorMessage }: ErrorboxProps) => {
  const isFetching = queries?.some(query => query.isFetching);

  const message =
    errorMessage ?? 'Kunne ikke hente data. Vennligst kontakt Intility om problemet vedvarer.';

  return (
    <Message className={'flex flex-wrap items-center gap-bfs-4'} state='alert'>
      <h5 className={'bf-p m-0 font-normal'}>{message}</h5>

      {queries && (
        <>
          <Icon icon={faRedo} fixedWidth spin={isFetching} />
          {!isFetching && (
            <button
              type='button'
              className='bf-link'
              onClick={() => {
                for (const query of queries) {
                  if (query.isError) {
                    query.refetch();
                  }
                }
              }}
            >
              Prøv igjen
            </button>
          )}
        </>
      )}
    </Message>
  );
};
