import { useBasket, useBasketActions } from '@/stores/basket';
import { useOrderActions, useOrders } from '@/stores/orders';
import { type DataPlanDto } from '../api/getDataPlans.schema';

export const useUpdateBasket = (product: DataPlanDto, handleModal?: () => void) => {
  const basket = useBasket();
  const orders = useOrders();
  const { removeOrder, removeOrders } = useOrderActions();
  const { addToBasket, removeFromBasket, removePresetsFromBasket } = useBasketActions();

  const basketItem = basket.find(_basketItem => _basketItem.dataPlanUuid === product.dataPlanId);
  const currentBasketQuantity = basketItem?.amount ?? 0;

  const handleRemoveFromBasket = (id: string, quantity?: number) => {
    const reversedOrders = orders.slice().reverse();
    const orderToRemove = reversedOrders.find(order => order.dataPlan.dataPlanId === id);

    if (orderToRemove) {
      removeOrder(orderToRemove.id);
    }

    removeFromBasket(id, quantity);

    if (handleModal && currentBasketQuantity <= 1) {
      handleModal();
    }
  };

  const handleRemoveAll = () => {
    removePresetsFromBasket(product.dataPlanId);
    removeOrders(product.dataPlanId);
  };

  const handleOnCounterInputChange = (quantity: number) => {
    if (quantity < currentBasketQuantity) {
      handleRemoveFromBasket(product.dataPlanId, quantity);
    } else {
      addToBasket(product, quantity);
    }
  };

  return {
    handleRemoveFromBasket,
    handleRemoveAll,
    handleOnCounterInputChange,
    addToBasket,
    removeFromBasket,
    removeOrder,
    currentBasketQuantity,
    basket,
  };
};
