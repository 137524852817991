import { Breadcrumbs, Grid, Icon, Message, Skeleton } from '@intility/bifrost-react';
import type { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import type { Entries } from 'type-fest';

import { Errorbox } from '@/components/Errors';
import { ContentLayout } from '@/components/Layout';
import { TableOfContents } from '@/components/TableOfContents';
import { routes } from '@/routes/config';
import { useDataPlans } from '../api/getDataPlans';
import { categoryMetadata } from '../assets/metadata';
import { ProductCategorySellingPoints } from '../components/ProductCategorySellingPoints';
import { ProductRow } from '../components/ProductRow';
import { categorizeDataPlans } from '../utils/products';

export const ProductCategories = () => {
  const dataPlans = useDataPlans();

  const smoothScrollToElement = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const id = e.currentTarget.getAttribute('href');
    const element = id && document.querySelector(id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const categorizedDataPlans = categorizeDataPlans(dataPlans.data?.items);

  const categorizedProductEntries = Object.entries(categorizedDataPlans) as Entries<
    typeof categorizedDataPlans
  >;

  return (
    <ContentLayout title='Velg abonnement' className='max-w-6xl'>
      <div className='flex md:gap-bfs-24'>
        <div className='grow'>
          <Breadcrumbs>
            <Breadcrumbs.Item>
              <Link to={routes.products.overview.path}>Kategorier</Link>
            </Breadcrumbs.Item>
            <Breadcrumbs.Item>Alle abonnementer</Breadcrumbs.Item>
          </Breadcrumbs>

          <h2 className='mb-bfs-24 mt-bfs-16 font-display text-3xl font-semibold'>
            Alle Abonnement
          </h2>

          <Grid className='gap-bfs-24'>
            {categorizedProductEntries.map(([productType, productsOfType]) => {
              const categoryInfo = categoryMetadata[productType];

              return (
                <Grid key={categoryInfo.id} id={categoryInfo.id}>
                  <div id={categoryInfo.id}>
                    <h3 className='mb-bfs-4 font-display text-2xl'>
                      {categoryInfo.categoryHeader}
                    </h3>
                    <p className='text-base'>{categoryInfo.categoryIngress}</p>
                  </div>

                  <ProductCategorySellingPoints categoryMetadata={categoryInfo} />

                  {dataPlans.isPending ? (
                    <Skeleton repeat={3}>
                      <div className='bg-bfc-base-3 p-bfs-24'>
                        <Skeleton.Text className='w-1/2 md:w-1/3' />
                        <Skeleton.Text className='w-1/3 md:w-1/4' />
                      </div>
                    </Skeleton>
                  ) : dataPlans.isError ? (
                    <Errorbox queries={[dataPlans]} />
                  ) : productsOfType.length ? (
                    <div className='rounded-lg border border-bfc-base-2-dimmed'>
                      {productsOfType.map(product => (
                        <ProductRow key={product.dataPlanId} product={product} />
                      ))}
                    </div>
                  ) : (
                    <Message state='neutral'>Kontakt oss for abonnementer</Message>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </div>

        <div>
          <TableOfContents stickyClassName='min-w-[200px]'>
            {categorizedProductEntries.map(([productType]) => {
              const categoryInfo = categoryMetadata[productType];

              return (
                <li key={categoryInfo.id}>
                  <a
                    onClick={smoothScrollToElement}
                    href={`#${categoryInfo.id}`}
                    className='bf-title-link'
                  >
                    {categoryInfo.icon && <Icon className='mr-bfs-8' icon={categoryInfo.icon} />}
                    {categoryInfo.categoryHeader}
                  </a>
                </li>
              );
            })}
          </TableOfContents>
        </div>
      </div>
    </ContentLayout>
  );
};
