import { useEffect, useState } from 'react';

export const useScrollPosition = (initialState = 0) => {
  const [scrollPosition, setScrollPosition] = useState(initialState);

  useEffect(() => {
    const onScroll = () => {
      setScrollPosition(window.scrollY);
      window.requestAnimationFrame(() => (window.scrollY > 0 ? window.scrollY : 0));
    };

    window.addEventListener('scroll', onScroll, false);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return { scrollPosition };
};
