import { Grid, Message, Skeleton } from '@intility/bifrost-react';

import { Errorbox } from '@/components/Errors';
import { useDataPlans } from '../api/getDataPlans';
import { type DataPlanDto } from '../api/getDataPlans.schema';
import type { SubscriptionProductType } from '../types/products';
import { FeaturedProductCard } from './FeaturedProductCard';
import { ProductRow } from './ProductRow';

interface ProductsDisplayProps {
  category: SubscriptionProductType;
}

export const ProductsDisplay = ({ category }: ProductsDisplayProps) => {
  const dataPlans = useDataPlans({ subscriptionCategoryType: category });

  const categoryDataPlans =
    dataPlans.data?.items
      .filter(dataPlan => dataPlan.subscriptionCategoryType === category)
      .sort((a, b) => b.includedByteDataLimit - a.includedByteDataLimit) ?? [];

  const featuredDataPlans: DataPlanDto[] = [];
  const remainingDataPlans: DataPlanDto[] = [];

  for (const dataPlan of categoryDataPlans) {
    if (featuredDataPlans.length >= 3) {
      remainingDataPlans.push(dataPlan);
      continue;
    }

    if (dataPlan.isFavorite || Boolean(dataPlan.tenantId)) {
      featuredDataPlans.push(dataPlan);
    } else {
      remainingDataPlans.push(dataPlan);
    }
  }

  return dataPlans.isError ? (
    <Errorbox queries={[dataPlans]} />
  ) : dataPlans.isPending ? (
    <Skeleton repeat={3}>
      <div className='bg-bfc-base-3 p-bfs-24'>
        <Skeleton.Text className='w-1/2' />
        <Skeleton.Text className='' />
      </div>
    </Skeleton>
  ) : (
    <div className='flex flex-col gap-bfs-24'>
      {[...featuredDataPlans, ...remainingDataPlans].length === 0 ? (
        <Message state='neutral'>Kontakt oss for priser</Message>
      ) : (
        <>
          <Grid small={3}>
            {featuredDataPlans.map(dataPlan => (
              <FeaturedProductCard key={dataPlan.dataPlanId} product={dataPlan} />
            ))}
          </Grid>

          <div>
            {remainingDataPlans.map(dataPlan => (
              <ProductRow key={dataPlan.dataPlanId} product={dataPlan} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
